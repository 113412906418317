<template>
  <div style="position: relative">
    <van-nav-bar :title="this.$store.state.taskTitle" left-arrow @click-left="onClickLeft"> </van-nav-bar>
    <Pos ref="Pos" />
    <div v-if="show">
      <div class="cardBox" style="font-size: 14px">
        <div style="font-weight: 900; margin-bottom: 3px">
          此任务一天至少完成 <span class="red">{{ taskTimes }}</span> 次，当前已完成
          <span class="red">{{ totalTimes }}</span> 次
        </div>
        <div>{{ tip }}</div>
      </div>
      <div class="cardBox">
        <div style="margin-bottom: 15px">1、刷脸</div>
        <Face :url="url" @clickFace="clickFace" />
      </div>
    </div>
    <BottomBtn :disabled="disabled()" @cancel="onClickLeft" @submit="submit" />
    <PosErrDialog ref="PosErrDialog" @confirm="confirmPosErrDialog" />
  </div>
</template>
<script>
// import { Toast } from 'vant'
import { Dialog, Toast } from 'vant'
import Pos from '@/components/sanmeUse/Position.vue'
import BottomBtn from '@/components/sanmeUse/BottomBtn.vue'
import Face from '@/components/taskDetails/Face.vue'
import PosErrDialog from '@/components/fwjh/PosErrDialog.vue'
import { getFWJH, reportFWJH } from '@/api/api.js'
import moment from 'moment'
export default {
  components: {
    Pos,
    Face,
    BottomBtn,
    PosErrDialog
  },
  data() {
    return {
      show: false,
      url: '', //视频路径
      faceUrl: '', //前往刷脸的路径
      msgText: '', //刷脸的显示内容
      protocolState: '', //刷脸的授权协议状态，0未授权，1已授权
      tip: '此任务当前可做', //当前可做 或 此任务 59分 后可做
      taskTimes: 0, //总次数
      totalTimes: 0, //已完成次数
      nextTime: 0,
      userFaceLogId: null, //后端传过来再传回去
      tenCode: null, //同上
      taskPerformCode: null
    }
  },
  methods: {
    async init() {
      let param = {}
      if (this.$route.query.faceTaskLogId) {
        param.faceTaskLogId = this.$route.query.faceTaskLogId
      }
      let res = await getFWJH(param)
      if (res.data.success) {
        this.show = true
        if (this.$route.query.faceTaskLogId) {
          this.$router.push({ query: {} }) //成功后清空query避免重复添加日志
        }
        let r = res.data.data[0]
        this.userFaceLogId = r.userFaceLogId
        this.nextTime = r.nextTime || 0
        this.taskTimes = r.taskTimes
        this.totalTimes = r.totalTimes
        this.tenCode = r.tenCode
        this.taskPerformCode = r.code
        this.url = r.faceVideoUrl
        this.faceUrl = r.faceCompareUrl
        this.msgText = r.msgText
        this.protocolState = r.protocolState
        if (this.nextTime) {
          this.tip = `此任务 ${this.nextTime}分 后可做`
        } else {
          this.tip = '此任务当前可做'
        }
        this.$refs.Pos.browser()
      }
    },
    onClickLeft() {
      location.href = this.$store.state.userInfo.backUrl || 'javascript:history.back();'
    },
    async toFace() {
      let sdk = await this.$store.state.getSdk('CRMEX-TXFACEVERIFY', () => {})
      if (sdk) {
        const message = this.msgText;
        if(this.protocolState == '1') {
          location.href = this.faceUrl
        } else {
          Dialog.alert({
            title: '提示',
            message: message || '该页面服务由第三方“腾讯云计算（北京）有限责任公司”提供',
            confirmButtonText: '我知道了',
            confirmButtonColor: '#24ADFE'
          }).then(() => {
            location.href = this.faceUrl
          })
        }
      }
    },
    clickFace() {
      if (this.nextTime != 0) {
        Dialog.alert({
          title: '提示',
          message: `亲，非常抱歉，此任务当前不可执行，请等待${this.nextTime}分钟后再来做任务哦！`,
          confirmButtonText: '我知道了',
          confirmButtonColor: '#24ADFE'
        })
      } else {
        this.toFace()
      }
    },
    disabled() {
      //禁用逻辑 1.正在获取定位中 2.没有视频
      if (this.$store.state.positionLoading || !this.url) {
        return true
      } else {
        return false
      }
    },
    report(value = null) {
      let params = {
        userCode: this.$store.state.userInfo.userCode, //用户编码
        userName: this.$store.state.userInfo.userName, //用户名称
        userOpenid: this.$store.state.userInfo.userOpenid, //用户微信openid
        userName: this.$store.state.userInfo.userName, //用户名称
        projectCode: this.$store.state.userInfo.projectCode, //项目编码
        projectName: this.$store.state.userInfo.projectName, //项目名称
        terminalCode: this.$store.state.userInfo.terminalCode, //终端编码
        terminalName: this.$store.state.userInfo.terminalName, //终端名称
        terminalProvince: this.$store.state.userInfo.terminalProvince, //终端省
        terminalCity: this.$store.state.userInfo.terminalCity, //终端市
        terminalArea: this.$store.state.userInfo.terminalArea, //终端区
        terminalTown: this.$store.state.userInfo.terminalTown, //终端镇
        terminalLongitude: this.$store.state.userInfo.terminalLongitude, //终端经度
        terminalLatitude: this.$store.state.userInfo.terminalLatitude, //终端纬度
        batchCode: this.$store.state.batchCode, //批次编码
        taskPerformCode: this.taskPerformCode, //任务执行编码
        taskSubCode: 'FWJH001', //子任务编码
        taskSubName: '服务激活', //加子任务名称
        taskProjectCode: this.$store.state.taskCode, //加模块编码
        taskProjectName: this.$store.state.taskTitle, //加模块名称
        reportDate: this.$store.state.userInfo.attdate, //上报日期
        reportTime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), //提报时间
        reportLongitude: this.$store.state.lng, //提报经度
        reportLatitude: this.$store.state.lat, //提报纬度
        reportDistance: this.$store.state.distance, //距离
        appCode: 'mika', //应用
        userFaceLogId: this.userFaceLogId,
        memo: value,
        tenCode: this.tenCode,
        taskNo: Number(this.totalTimes) + 1
      }
      reportFWJH(params).then((res) => {
        if (res.data.success) {
          Toast.success({
            message: '提报成功',
            forbidClick: true,
            duration: 3000
          })
          setTimeout(() => {
            this.onClickLeft()
          }, 2000)
        }
      })
    },
    confirmPosErrDialog(value) {
      this.report(value)
    },
    //提交
    submit() {
      //如果没有定位 或者 距离大于800
      if (!this.$store.state.lng || this.$store.state.distance > 800) {
        this.$refs.PosErrDialog.showDialog()
      } else {
        this.report()
      }
    }
  },
  mounted() {
    //禁用返回功能
    this.$store.state.disableBack()
    this.$gobal.controlOptionMenu()
    this.init()
  }
}
</script>
<style lang="less" scoped>
.cardBox {
  background: #fff;
  margin-bottom: 10px;
  padding: 15px 5.3% 20px 5.3%;
  color: #333333;
}
.red {
  color: #ff2400;
}
</style>
