<template>
  <div>
    <van-dialog
      v-model="show"
      show-cancel-button
      confirm-button-text="继续提交"
      confirmButtonColor="#24ADFE"
      cancelButtonColor="#646566"
      cancel-button-text="关闭提示"
      :beforeClose="beforeClose"
    >
      <div style="text-align: center; height: 50px; line-height: 50px; font-weight: 600">提示</div>
      <div style="padding: 0 20px 20px 20px">
        <div style="text-align: left; font-size: 14px; text-indent: 2em; color: #646566">
          <div>亲，您的定位异常，您可以关闭弹框后刷新定位，或者在下面填写您位置异常的原因后再提交。</div>
          <div>如果不填写原因继续提交的话，此次任务可能会直接按照异常处理哦！</div>
        </div>
        <div
          style="border: 1px solid #ccc; border-radius: 4px; padding: 2px 10px; position: relative; margin-top: 14px"
          @click="showPopup = true"
        >
          <div style="height: 24px; font-size: 14px; line-height: 1.8" :class="value ? '' : 'holder'">
            {{ value || '请选择原因' }}
          </div>
          <van-icon name="arrow-down" class="icon" />
        </div>
        <van-field
          v-if="value === '其他'"
          type="textarea"
          placeholder="请输入"
          class="areaBox"
          maxlength="150"
          show-word-limit
          v-model.trim="otherValue"
          autosize
        >
        </van-field>
      </div>
    </van-dialog>
    <van-popup v-model="showPopup" position="bottom" :style="{ height: '30%' }">
      <van-picker title="" show-toolbar :columns="columns" @confirm="pickerConfirm" @cancel="showPopup = false" />
    </van-popup>
  </div>
</template>
<script>
import { Toast } from 'vant'
export default {
  data() {
    return {
      show: false,
      showPopup: false,
      value: '',
      otherValue: '',
      columns: ['外出参加培训', '外出支援活动', '外出送货/取货', '疫情管控', '其他']
    }
  },
  methods: {
    showDialog() {
      this.show = true
    },
    pickerConfirm(val) {
      this.value = val
      this.showPopup = false
    },
    beforeClose(action, done) {
      if (!this.value && action === 'confirm') {
        Toast('请选择原因')
        done(false)
      } else if (action === 'confirm') {
        const value = this.otherValue ? this.otherValue : this.value
        this.$emit('confirm', value)
        done()
      } else {
        done()
      }
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .van-cell {
  padding: 0;
}
.icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #ccc;
}
.holder {
  color: #c8c9cc !important;
}
.areaBox {
  width: 100%;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  resize: none;
  margin-top: 6px;
  text-indent: 5px;
  padding-left: 10px;
}
</style>
