<template>
  <div style="padding: 0 0 0 23px">
    <div style="display: flex">
      <div style="margin-right: 20px">
        <div class="up" v-if="!url" @click="face">
          <van-icon name="photograph" style="color: #dcdee0; font-size: 24px" class="icon" />
        </div>
        <video
          v-else
          :src="url"
          controls
          style="width: 130px; height: 120px"
          webkit-playsinline="true"
          x-webkit-airplay="true"
          playsinline="true"
          x5-video-player-type="h5"
          preload="meta"
        >
          您的浏览器不支持 video 标签。
        </video>
      </div>
      <span style="font-size: 14px"
        >点击左图标进行刷脸，请根据页面提示依次完成刷脸动作，请在刷脸后15分钟内进行提交，否则需要重新刷脸</span
      >
    </div>
  </div>
</template>
<script>
export default {
  props: {
    //当前视频的路径
    url: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  methods: {
    face() {
      this.$emit('clickFace')
    }
  },
  mounted() {}
}
</script>
<style lang="less" scoped>
.up {
  min-width: 80px;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f7f8fa;
  .icon {
    color: #dcdee0;
    font-size: 24px;
  }
}
</style>
