<template>
  <div class="btnBox">
    <van-button class="cancel" type="default" @click="onClickLeft" native-type="button"> 取消 </van-button>
    <van-button
      class="submit"
      type="primary"
      :disabled="disabled"
      :class="disabled ? 'disabledSub' : ''"
      @click="submit"
    >
      确定
    </van-button>
  </div>
</template>
<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClickLeft() {
      this.$emit('cancel')
    },
    submit() {
      this.$emit('submit')
    },
  }
}
</script>
<style lang="less" scoped>
.btnBox {
  width: 94.6%;
  height: 45px;
  position: fixed;
  bottom: 0;
  background: #fff;
  padding: 10px 2.7%;
  display: flex;
  button {
    width: 50%;
  }
  .submit {
    border-radius: 0 22px 22px 0;
  }
  .cancel {
    border-radius: 22px 0 0 22px;
    background: #e6fbff;
    border: 1px solid #76d7f5;
    color: #1a99ff;
  }
}
.disabledSub {
  background: #ccc;
  border-color: #ccc;
}
</style>
