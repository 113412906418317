<template>
  <div
    class="posBox"
    :style="{
      background: posError || this.$store.state.lng == '' ? '#fdeceb' : '#FFFBE6',
      color: posError || this.$store.state.lng == '' ? '#ff4d4f' : '#FAAD14'
    }"
  >
    <div class="flexBox">
      <!-- {{$store.state.positionLoading}} -->
      <div>
        <span>
          <van-icon class="location-o" name="location-o" style="margin-right: 4px" />
          <span>{{ this.$store.state.nowAddress || '未获取到定位信息' }}</span>
        </span>
        <div style="padding-left: 21px" v-if="this.$store.state.lng !== 0">
          {{ showDistance() }}
        </div>
      </div>
      <van-button
        v-if="posError || this.$store.state.lng == ''"
        @click="browser"
        type="default"
        :loading="false"
        class="btn"
        loading-text="定位中..."
        native-type="button"
        style="position: relative; top: 4px"
      >
        <div class="flexBox">
          <img
            src="@/assets/icon/2-刷新.svg"
            alt=""
            style="height: 15px; width: 15px; margin-right: 2px; position: relative; top: 3px"
          />
          <span style="white-space: nowrap">位置异常</span>
        </div>
      </van-button>
    </div>
  </div>
</template>
<script>
import { browserPos } from '@/api/baiduMap.js'
export default {
  data() {
    return {
      posError: true, // 定位是否异常
      posCurrent: 3 //如果连续调用三次定位扔没有返回结果取前端腾讯定位传过来的定位信息
    }
  },
  methods: {
    //进行定位
    browser() {
      this.posCurrent--
      browserPos(this.posCurrent)
    },
    //展示距离  无门店左边0.1  无定位N 其他正常
    showDistance() {
      if (this.$store.state.lng === 0 || this.$store.state.lat === 0) {
        return '与门店偏差距离 n 米'
      } else if (
        this.$store.state.userInfo.terminalLongitude == null ||
        this.$store.state.userInfo.terminalLatitude == null
      ) {
        return '门店没有GPS'
      } else {
        return `与门店偏差距离 ${this.$store.state.distance} 米`
      }
    }
  },
  computed: {
    //监听是否重新计算距离
    watch_distance() {
      return this.$store.state.distance
    }
  },
  watch: {
    watch_distance() {
      if (
        this.$store.state.distance <= 800 ||
        this.$store.state.userInfo.terminalLongitude == null ||
        this.$store.state.userInfo.terminalLatitude == null
      ) {
        this.posError = false
      } else {
        this.posError = true
      }
    }
  }
}
</script>
<style lang="less" scoped>
.posBox {
  line-height: 24px;
  min-height: 24px;
  padding: 13px 4% 13px 5.6%;
  font-size: 14px;
  .location-o {
    font-size: 16px;
  }
  .btn {
    padding: 5px;
    background: #fdeceb;
    color: #ff4d4f;
    font-size: 12px;
    margin-left: 10px;
    border: 1px solid #ffbab7;
    border-radius: 2px;
    width: 80px;
    height: 20px;
    line-height: 20px;
  }
}
.flexBox {
  display: flex;
  align-items: flex-start;
}
</style>
